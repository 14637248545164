<template>
  <div>
    <v-skeleton-loader v-if="showPageLoader"
      ref="skeleton"
      type="table"
      :tile="false"
      class="mx-auto"
    ></v-skeleton-loader>
    <div v-else>
      <compact-list v-if="currentView.value === 1 && !showPageLoader" :payload="listPayloadObj" :total="total" :activeFilterView="activeFilterView" @delete="onDeleteListItem"
       @editPageView="editPageView" :conditions="conditions" @listSortingEmitter="updateListWithSorting($event)" :listOfFilterViews="listOfFilterViews" @createPageView="dialog = true"
      @setCurrentView="setCurrentView" @getCount="getRecordsCount" :showCount="showCount" :countLoading="countLoading" :hasMoreRecords="hasMoreRecords"></compact-list>
      <!-- <compact-list v-if="currentView.value === 1 && !showPageLoader" :payload="listPayloadObj" :total="total" @delete="onDeleteListItem" :conditions="conditions"
      @listSortingEmitter="updateListWithSorting($event)" @setCurrentView="setCurrentView"></compact-list> -->
      <custom-dashboard v-else-if="!showPageLoader" :currentModule="currentModule" @setCurrentView="setCurrentView"></custom-dashboard>
    </div>
    <v-dialog persistent v-model="dialog" width="300">
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="px-4 py-2">
          {{ filterBasedView._id ? $t('updateView') : $t('saveView') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="saveFilterView">
          <v-card-text class="pt-5 pb-0">
            <v-text-field outlined dense v-model="filterBasedView.name" label="view name" :rules="$_requiredValidation" @change="checkForDuplicateFilterViewName(filterBasedView.name)"></v-text-field>
            <!-- <v-switch dense v-model="filterBasedView.isdefault" label="is default" class="mt-0"></v-switch> -->
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small :loading="filterLoader" @click="filterBasedView._id ? updateFilterView() : saveFilterViewHandler()" color="success">{{ filterBasedView._id ? $t('update') : $t('save') }}</v-btn>
          <v-btn small @click="dialog = false" color="error">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonFunctions from './mixin'
// eslint-disable-next-line
// import { filter } from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
  mixins: [CommonFunctions],
  data () {
    return {
      moduleName: this.$route.params.name,
      moduleId: this.$route.params.id,
      allFields: [],
      loadingFields: false,
      loading: false,
      pagination: this.$store.state.common[`${this.$route.params.name}_INDEX`] ? !this.$formatter.isEmptyObject(this.$store.state.common[`${this.$route.params.name}_INDEX`]) ? this.$store.state.common[`${this.$route.params.name}_INDEX`] : { itemsPerPage: 8, page: 1 } : { itemsPerPage: 8, page: 1 },
      MODULE: 'modules',
      headers: [],
      item: null,
      searchArray: [],
      showFilter: false,
      /* pwa */
      showPageLoader: true,
      selectedFilterItems: [],
      listPayloadObj: {
        hideHeader: false,
        addClickHandler: () => this.$router.push(`/module/${this.moduleName}/${this.moduleId}/actions`),
        editHandler: (item) => this.$router.push(`/module/${this.moduleName}/${this.moduleId}/view/${item._id}`),
        sortClickHandler: (type) => this.getListHandler({ sortBy: type, skip: 0, limit: 25 }),
        moduleTitle: this.$route.params.name,
        hasNoNext: false,
        loading: false,
        list: [],
        pagination: {},
        storeIndex: `${this.$route.params.name}_INDEX`,
        items: [],
        hasFilter: true,
        moduleIcon: '', // mdi-cube
        hasPermission: false,
        completeList: [],
        hasSearch: true,
        sortBy: 1,
        allActiveHeaders: [],
        listOfSubModuleFieldsActive: [],
        filterArray: [],
        sortByField: 0
      },
      listOfAllActiveFields: [],
      allActiveFieldsList: [],
      currentModule: {},
      deleteSuccessPagination: null,
      hasManagePermission: false,
      total: 0,
      listOfFilterViews: [],
      activeFilterView: {},
      columnSortOrder: [],
      appliedFilters: [],
      filterBasedView: {
        name: null,
        isdefault: false
      },
      dialog: false,
      filterLoader: false,
      conditions: [],
      currentView: {},
      hasMoreRecords: false,
      countLoading: false,
      showCount: false
    }
  },
  components: {
    'compact-list': () => import('@/components/CompactList'),
    'custom-dashboard': () => import('@/components/CustomDashboard.vue')
  },
  computed: {
    ...mapGetters(['listOfModules', 'getUsers', 'userDetails', 'getListOfFiltersHistory', 'systemDetails']),
    paginationObj () {
      return { sortBy: ['name'], itemsPerPage: this.userDetails.userpagination || 25, sortDesc: [false], search: '', page: 1, mustSort: true }
    }
  },
  created () {
    this.getCurrentView()
    // this.getFieldsList(this.moduleId)
    this.$store.dispatch('getModuleList').then(() => {
      const moduleObj = this.listOfModules.find(x => x.name === this.$route.params.name)
      if (moduleObj && moduleObj.icon) this.listPayloadObj.moduleIcon = moduleObj.icon
    })
  },
  watch: {
    pagination: {
      handler (val) {
        if (val) {
          const model = {
            type: [`${this.moduleName}_INDEX`],
            data: val
          }
          this.$store.commit('savePagination', model)
        }
      },
      deep: true
    },
    '$store.state.common.listOfModules' (val) {
      this.checkModulePermission()
      return ''
    }
  },
  mounted () {
    this.checkModulePermission()
    var currentModule = this.$formatter.cloneVariable(this.listOfModules.find(x => x._id === this.moduleId))
    if (currentModule) {
      this.listPayloadObj.profileEnabled = currentModule.enableprofile
      this.currentModule = currentModule
    }
    const localStorageFilter = window.localStorage.getItem(this.moduleName)
    if (localStorageFilter) {
      const filters = JSON.parse(localStorageFilter)
      this.searchArray = filters.searchArray
      if (this.searchArray.length > 0) this.showFilter = true
    }
    this.$eventBus.$on('loadList', (data) => {
      // if (!this.listPayloadObj.isSubmodule && this.activeFilterView && !this.$formatter.isEmptyObject(this.activeFilterView)) this.updatePageView({ pagination: data, type: 'scroll' })
      // else this.getListHandler(data)
      this.getListHandler(data)
    })
    this.$eventBus.$on('resetFilter', (data) => {
      this.listOfAllActiveFields = data
    })

    this.$eventBus.$on('showHideFilter', () => {
      // this.showFilter = !this.showFilter
      this.$root.$emit('filters', {
        fields: this.$formatter.cloneVariable(this.listOfAllActiveFields),
        callback: (conditions) => {
          this.conditions = conditions || []
          this.updatePageView({ conditions: this.conditions, type: 'filters' })
        }
      })
    })

    this.$root.$on('changeActiveFilterView', async (item) => {
      this.setCurrentPageViewInLocalStorage(item)
      if (this.activeFilterView._id !== item._id) {
        this.listPayloadObj.sortBy = 1
        this.listPayloadObj.sortByField = 0
        this.changeActivePageView(item)
        const sortedFields = await this.sortTableHeaderBasedOnSelectedView(this.listPayloadObj.listOfAllActiveFields)
        this.headers = this.$formatter.cloneVariable(sortedFields.filter(x => x.show_in_list)).splice(0, 4)
        if (this.listPayloadObj) this.listPayloadObj.items = this.$formatter.cloneVariable(sortedFields.filter(x => x.show_in_list)).splice(0, 4)
        this.getListHandler() // This method should be called after the above three lines, else it will cause issue in sorting
      }
    })
    this.getFieldsListAndLoadData()
  },
  methods: {
    checkForDuplicateFilterViewName (name) {
      if (this.listOfFilterViews && this.listOfFilterViews.length) {
        const names = this.listOfFilterViews.map(x => x.name)
        if (names.includes(name)) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: this.$t('alreadyExistView') })
        }
      }
    },
    updatePageView ({ conditions, type }) {
      const filters = this.constructFilterList(conditions)
      const model = this.$formatter.cloneVariable(this.activeFilterView)
      if (type) model.appliedfilter.filters = filters
      // switch (type) {
      //   case 'filters':
      //     model.appliedfilter.filters = filters
      //     break
      // case 'scroll':
      //   model.appliedfilter = { ...model.appliedfilter, ...pagination }
      //   break
      // case 'sort': {
      //   const sortInPagination = (model && model.appliedfilter && model.appliedfilter.pagination) ? JSON.parse(model.appliedfilter.pagination) : {}
      //   sortInPagination.sortBy = [this.headers[sortByField].name]
      //   sortInPagination.sortDesc = [sortBy === -1]
      //   model.appliedfilter.pagination = JSON.stringify(sortInPagination)
      //   model.appliedfilter = { ...model.appliedfilter, ...{ sortby: sortBy, sortbyfield: this.headers[sortByField].name } }
      //   if (pagination) model.appliedfilter = { ...model.appliedfilter, ...pagination }
      // }
      // break
      //   default:
      //     break
      // }
      this.listPayloadObj.loading = true
      this.$api.execute('put', `pageviews/${model._id}`, model)
        .then(({ data }) => {
          const index = this.listOfFilterViews.findIndex(x => x._id === data._id)
          this.listOfFilterViews[index] = this.$formatter.cloneVariable(data)
          this.changeActivePageView(data)
          this.getListHandler()
          // this.activeFilterView = this.$formatter.cloneVariable(data)
          // this.columnSortOrder = (data && data.modulefields && data.modulefields) ? JSON.parse(data.modulefields) : null
          // if (data.appliedfilter && data.appliedfilter.filters && data.appliedfilter.filters.length) {
          //   this.appliedFilters = data.appliedfilter.filters.map(x => {
          //     return JSON.parse(x.element)
          //   })
          // } else this.appliedFilters = []
          // const { conditions } = this.constructFilterWithFields(this.appliedFilters)
          // this.conditions = conditions
          // this.getListHandler(pagination)
        })
    },
    changeActivePageView (item) {
      this.activeFilterView = this.$formatter.cloneVariable(item)
      // this.columnSortOrder = (item && item.modulefields && item.modulefields) ? JSON.parse(item.modulefields) : null
      this.columnSortOrder = this.getStoredPageViewFields(this.activeFilterView)
      if (item.appliedfilter && item.appliedfilter.filters && item.appliedfilter.filters.length) {
        this.appliedFilters = item.appliedfilter.filters.map(x => {
          return JSON.parse(x.element)
        })
      } else this.appliedFilters = []
      const { conditions } = this.constructFilterWithFields(this.appliedFilters)
      this.conditions = conditions
      // this.getListHandler()
    },
    editPageView (id) {
      this.dialog = true
      this.filterBasedView = this.$formatter.cloneVariable(this.listOfFilterViews.find(x => x._id === id))
    },
    getCurrentView () {
      const currentView = localStorage.getItem(`${process.env.VUE_APP_NAME}_pwa_custom_view`) ? JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_pwa_custom_view`)) : null
      if (currentView) this.currentView = currentView
      else this.currentView = { name: 'list', value: 1 }
    },
    setCurrentView (item) {
      localStorage.setItem(`${process.env.VUE_APP_NAME}_pwa_custom_view`, JSON.stringify(item))
      this.currentView = item
      this.listPayloadObj.loading = true
      if (this.currentView.value === 1) {
        this.getFieldsListAndLoadData()
      }
    },
    onDeleteListItem (paginationObj) {
      this.pagination = paginationObj
      this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
      this.getListHandler()
    },
    async getFieldsListAndLoadData () {
      this.listPayloadObj.loadingFields = true
      if (!this.listPayloadObj.isSubmodule) await this.getAllFilterViews()
      await this.getFieldsList(this.moduleId)
      this.listPayloadObj.loadingFields = false
      if (this.appliedFilter) {
        const { conditions } = this.constructFilterWithFields(this.appliedFilters)
        this.conditions = conditions
      }
      // this.updatePageView({ type: 'sort', pagination: { skip: 0, limit: this.activeFilterView.appliedfilter.limit }, sortBy: 1, sortByField: 0 })
      this.getListHandler()
    },
    constructFilterWithFields (filterItem) {
      // let filtersHistory = localStorage.getItem('BusinessFlow365_filtersHistory')
      // filtersHistory = filtersHistory ? JSON.parse(filtersHistory) : []
      // const filterItem = filtersHistory.find((filterItem) => filterItem.moduleID === this.$route.params.id)
      if (!filterItem) return { filters: this.headers, conditions: [] }
      if (this.headers && this.headers.length) {
        const output = []
        for (const headerItem of this.headers) {
          if (filterItem && filterItem.length) {
            const isItemAlreadyExists = filterItem.find((item) => (item.field === headerItem.field))
            output.push(isItemAlreadyExists ? { ...headerItem, ...isItemAlreadyExists } : headerItem)
          } else output.push(headerItem)
        }
        return { filters: output, conditions: filterItem }
      }
    },
    getListHandler (payload) {
      this.listPayloadObj = Object.assign(this.listPayloadObj, { ...payload })
      this.getListOfRecords({ type: this.MAINLIST, ...payload })
      this.showCount = false
      this.total = 0
    },
    updateFilterView () {
      this.filterLoader = true
      const model = this.$formatter.cloneVariable(this.filterBasedView)
      this.activeFilterView = model
      this.$api.execute('put', `pageviews/${model._id}`, model)
        .then(({ data }) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updateSuccess' })
          const index = this.listOfFilterViews.findIndex(x => x._id === data._id)
          const listOfFilters = this.$formatter.cloneVariable(this.listOfFilterViews)
          listOfFilters[index] = this.$formatter.cloneVariable(data)
          this.listOfFilterViews = listOfFilters
        })
        .finally(() => {
          this.dialog = false
          this.filterLoader = false
        })
    },
    toggle (index) {
      this.allFields[index].selectBox = !this.allFields[index].selectBox
    },
    showMenu (index) {
      this.allFields[index].selected = !this.allFields[index].selectBox ? (this.allFields[index].type === 6 ? 'today' : 'is') : this.allFields[index].selected
      if (this.allFields[index].selectBox) this.selectedFilterItems.push(index)
      this.allFields[index].selectBox = this.allFields[index].menu = true
    },
    closeFilterSelection (item, index) {
      this.allFields[index].menu = false
      let isItemInSearchArray = true
      if (this.searchArray.length > 0) {
        const tempArray = this.$formatter.cloneVariable(this.searchArray)
        let keyStrings = []
        tempArray.forEach(el => {
          keyStrings = [...keyStrings, ...Object.keys(el)]
        })
        isItemInSearchArray = keyStrings.some(v => v.includes(item.value))
      }
      if (!isItemInSearchArray || this.searchArray.length === 0) {
        this.selectedFilterItems.splice(this.selectedFilterItems.indexOf(index), 1)
        this.allFields[index].selectBox = false
      }
      this.$set(this.allFields, index, { ...this.allFields[index] })
    },
    updateListWithSorting ({ sortBy, sortByField }) {
      setTimeout(() => {
        // this.updatePageView({ sortBy, sortByField, type: 'sort' })
        this.$set(this.listPayloadObj, 'sortBy', sortBy)
        this.$set(this.listPayloadObj, 'sortByField', sortByField)
        this.getListOfRecords({ type: this.MAINLIST })
      })
    },
    setCancelFilter (index, type) {
      this.allFields[index].selectBox = type === 'save' || type === 'edit'
      this.allFields[index].selectBox = type === 'save' || type === 'edit'
      this.allFields[index].menu = type === 'edit'
      if (type === 'cancel') {
        this.allFields[index].inputValue = ''
        this.allFields[index].selected = this.allFields[index].type === 6 ? 'today' : 'is'
        this.allFields[index].selectBox = false
      }
      this.$set(this.allFields, index, { ...this.allFields[index] })
      if (type === 'cancel') {
        this.selectedFilterItems.splice(this.selectedFilterItems.indexOf(index), 1)
        this.constructValues()
      } else {
        // Below conditions added for avoiding paginate query making when there is no data searchText
        const item = this.allFields[index]
        var result = false
        if (item.type !== 6) result = Boolean(item.inputValue)
        else {
          if (['today', 'thismonth', 'thisweek', 'withinnext', 'withinpast'].includes(item.selected)) result = true
          else if (['withinnext', 'withinpast'].includes(item.selected)) result = Boolean(item.within)
          else {
            if (['created_at', 'modified_at', 'before', 'after'].includes(item.selected || item._id)) result = Boolean(item.date)
            if (item.selected === 'between') result = Boolean(item.startdate && item.enddate)
          }
        }
        if (result) this.constructValues()
      }
    },
    constructValues () {
      const searchArray = []
      const getActiveItems = this.allFields.filter(x => x.selectBox)
      // this.saveOrRemoveFiltersFromLocalStorage()
      if (getActiveItems.length > 0) {
        getActiveItems.forEach(element => {
          var value = {}
          for (const i in element) {
            if (!['field', 'selected', 'type', '_id', 'dbRef', 'selectBox', 'show', 'text', 'value'].includes(i)) value[i] = element[i]
          }
          searchArray.push({ field: element._id, fieldtype: element.type, condition: element.selected, value: JSON.stringify(value) })
          // const obj = {}
          // switch (element.selected) {
          //   case 'is':
          //     if (element.default_value && element.default_value.is_multiselect) {
          //       obj[`Data.${[element.value]}`] = { $eq: `${JSON.stringify(element.serachText)}` }
          //     } else {
          //       obj[`Data.${[element.value]}`] = { $eq: `${element.serachText}` }
          //     }
          //     break
          //   case 'isnot': obj[`Data.${[element.value]}`] = { $ne: `${element.serachText}` }
          //     break
          //   case 'contains': obj[`Data.${[element.value]}`] = { $regex: `${element.serachText}`, $options: 'i' }
          //     break
          //   // eslint-disable-next-line
          //   case 'startswith':  obj[`Data.${[element.value]}`] = { '$regex': `(\s+${element.serachText}|^${element.serachText})`, $options: 'i' }
          //     break
          //   // eslint-disable-next-line
          //   case 'endswith': obj[`Data.${[element.value]}`] = { '$regex': `(\s+${element.serachText}|${element.serachText}$)`, $options: 'i' }
          // }
          // searchArray.push(obj)
        })
      }
      // const localStorageObj = {
      //   activeItems: getActiveItems,
      //   searchArray
      // }
      // window.localStorage.setItem(this.moduleName, JSON.stringify(localStorageObj))
      this.searchArray = searchArray
      this.getListHandler()
    },
    // removeFilter (item) {
    //   const localStorageFilter = window.localStorage.getItem(this.moduleName)
    //   if (localStorageFilter) {
    //     const filters = JSON.parse(localStorageFilter)
    //     const index = filters.activeItems.findIndex(x => x._id === item._id)
    //     filters.activeItems.splice(index, 1)
    //     filters.searchArray.splice(index, 1)
    //     this.searchArray = filters.searchArray
    //     window.localStorage.setItem(this.moduleName, JSON.stringify(filters))
    //   }
    //   this.getListHandler()
    // },
    saveOrRemoveFiltersFromLocalStorage () {
      const array = this.allFields.filter(x => x.selectBox)
      if (this.getListOfFiltersHistory.length) {
        const alreadyStoredIndex = this.getListOfFiltersHistory.findIndex(x => x.moduleID === this.moduleId)
        if (alreadyStoredIndex >= 0) this.getListOfFiltersHistory[alreadyStoredIndex].filters = array
        else this.getListOfFiltersHistory.push({ name: this.moduleName, moduleID: this.moduleId, filters: array, searchTerm: '' })
      } else this.getListOfFiltersHistory.push({ name: this.moduleName, moduleID: this.moduleId, filters: array, searchTerm: '' })
      localStorage.setItem('BusinessFlow365_filtersHistory', JSON.stringify(this.getListOfFiltersHistory))
      this.$store.commit('setFiltersHistory')
    },
    checkModulePermission () {
      if (this.listOfModules.length > 0) {
        const modulename = this.$route.params.name
        const value = this.listOfModules
        const isExists = value.find(x => x.name === modulename)
        if (!isExists || (isExists && !isExists.isactive)) this.$router.push('/dashboard')
        this.showPageLoader = false
        // if (isExists) {
        //   const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleId, this.userDetails)
        //   this.listPayloadObj.isHideAdd = !hasManagePermission
        //   this.listPayloadObj.hasPermission = hasManagePermission
        //   this.hasManagePermission = hasManagePermission
        //   this.showPageLoader = false
        // } else {
        //   this.$router.push('/dashboard')
        // }
      }
    },
    setPaginationForFilterView (pagination) {
      let paginationObj = this.$formatter.cloneVariable(pagination)
      if (paginationObj && !this.$formatter.isEmptyObject(paginationObj)) {
        if (paginationObj.sortBy.length === 0) paginationObj.sortBy = ['name']
      } else {
        paginationObj = this.$formatter.cloneVariable(this.paginationObj)
      }
      this.pagination = null
      this.pagination = { ...paginationObj }
    },
    getRecordsCount (data) {
      this.countLoading = data
      this.getListOfRecordsCount({ type: this.MAINLIST })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$root.$off('deleteItems')
    this.$eventBus.$off('showHideFilter')
    this.$eventBus.$off('loadList')
    this.$eventBus.$off('resetFilter')
    this.$root.$off('changeActiveFilterView')
  }
}
</script>
<style scoped>
div.v-menu__content {
  top: 36px !important;
}
</style>
